import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from "axios";
import './MemberProfile.css';
import { Modal } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TimelineProfile from './TimelineProfile';
import './TimelineProfile.css'; // Import the CSS file
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Link } from 'react-router-dom';
import { CustomLoader, exportTableData } from '../utils/CommonLibrary';
import { fetchMember360Profile } from '../../services/medengage/Service';
import { NotificationToast, showToast } from '../componenents/ContentCareNavigator/NotificationToast';
import { RenderStringWithBreaks } from '../utils/CommonLibrary';
import HealthLockerMP from './HealthLockerMP';


function MemberProfile() {

  //Health Locker
  const [showHealthLockerModal, setShowHealthLockerModal] = useState(false);
  const handleShowHealthLockerModal = () => {
    setShowHealthLockerModal(true);
  }
  const handleCloseHealthLockerModal = () => setShowHealthLockerModal(false);

  //==============

  const [searchStr, setSearchStr] = useState("");
  const [eventsFound, setEventsFound] = useState(true);
  const [memberBasicInfo, setMemberBasicInfo] = useState([]);
  const [events, setEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [memberPathways, setMemberPathways] = useState([]);
  const [familyMembers, setFamilyMembers] = useState([]);
  const [showUserProfile, setShowUserProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [distinctActions, setDistinctActions] = useState([]);
  const [filterText, setFilterText] = useState([]);
  const [showTelehealth, setShowTelehealth] = useState("N");
  const [showHealthLocker, setShowHealthLocker] = useState("N");
  const [serviceLogs, setServiceLogs] = useState([]);

  //CN Calls
  const [callDetails, setCallDetails] = useState(false);
  const [showCallDetailModal, setShowCallDetailModal] = useState(false);

  //Home Based Service 
  const [fileUrl, setFileUrl] = useState('');
  const [hbsRequestDetail, setHbsRequestDetail] = useState({});
  const [showMr, setShowMr] = useState(false);
  const [showLr, setShowLr] = useState(false);
  const [showHcr, setShowHcr] = useState(false);
  const [showHbv, setShowHbv] = useState(false);
  const [hbsModalHeader, setHbsModalHeader] = useState("");
  const [showHBSDetailModal, setShowHBSDetailModal] = useState(false);

  useEffect(() => {
    if (filterText.length === 0) {
      setEvents(allEvents);
    } else {
      setEvents(allEvents.filter(event => filterText.includes(event.action)));
    }
  }, [filterText, allEvents]);

  const handleSubmit = async (event) => {
    setEventsFound(true);
    if (event) event.preventDefault();
    const searchString = document.getElementById("searchStr").value.trim();
    setShowUserProfile(false);
    if ((searchStr).trim() !== "") {
      setLoading(true);
      const request = axios.CancelToken.source();
      let ct = {
        cancelToken: request.token
      }

      await fetchMember360Profile(searchString)
        .then((response) => {
          if (response.data.code === 200) {
            setShowUserProfile(true);
            setMemberBasicInfo(response.data.data.info);
            setMemberPathways(response.data.data.info.memberPathways);
            setFamilyMembers(response.data.data.info.family);
            setEvents(response.data.data.info.events);
            setAllEvents(response.data.data.info.events);
            setShowTelehealth(response.data.data.info.showTelehealth);
            setShowHealthLocker(response.data.data.info.showHealthLocker);

            if (response.data.data.info.events.length <= 0) {
              setEventsFound(false);
            } else {
              setEventsFound(true);
            }

            const da = [...new Set(response.data.data.info.events.map(event => event.action))].sort();
            setDistinctActions(da);

          } else {
            showToast(response.data.data.message, 'error', 'top-right');
          }
          setLoading(false);
        }).catch(function (error) {
          console.log(error);
        });
      return () => request.cancel();
    } else {
      showToast("Please enter member ID", 'error', 'top-right');
    }
  }

  const handleExport = () => {
    const profileData = [
      {
        'Name': memberBasicInfo.memberName,
        'Email': memberBasicInfo.email
      }
    ]
    const modifiedData = events.map(item => {
      return {
        'Date': item.readableDate,
        'Action': item.action,
        'Description': item.description
      };
    });
    const mergedData = [...profileData, ...modifiedData];

    exportTableData("MemberProfile_" + memberBasicInfo.membershipNumber + "_", modifiedData);
  }

  const handleFamilyMemberProfileClick = (membershipNumber) => {
    setSearchStr(membershipNumber);
    document.getElementById("searchStr").value = membershipNumber.trim();
    document.getElementById("submitButton").click();
  }

  const handleTagClick = (item) => {
    setFilterText((prevFilterText) => {
      if (prevFilterText.includes(item)) {
        return prevFilterText.filter((i) => i !== item);
      } else {
        return [...prevFilterText, item];
      }
    });
  };

  const handleDetailsModal = async (keyword, itemDetails) => {
    setShowMr(false);
    setShowLr(false);
    setShowHcr(false);
    setShowHbv(false);

    if (keyword == "cncalls") {
      setCallDetails(itemDetails);
      setShowCallDetailModal(true);
    }

    if (keyword == "hbsmedications" || keyword == "hbslab" || keyword == "hbshomecare" || keyword == "hbshomebasedvaccines") {
      setHbsRequestDetail(itemDetails);
      if (keyword === "hbsmedications") {
        setHbsModalHeader("Medication Request");
        setShowMr(true);
      } else if (keyword === "lr") {
        setHbsModalHeader("Lab Request");
        setShowLr(true)
      } else if (keyword === "hcr") {
        setHbsModalHeader("Health Care Request");
        setShowHcr(true)
      } else {
        setHbsModalHeader("Home Based Vaccine");
        setShowHbv(true)
      }
      setShowHBSDetailModal(true);
    }

  }

  return (
    <>
      {/* Uncomment Navbar if needed */}
      {/* <Navbar /> */}
      <NotificationToast />
      {
        loading === true ? <CustomLoader /> : ""
      }

      <section className='ReportsPrograms'>
        <div className="container-fluid px-0 mb-3 ">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">Member Journey
              </h1>
            </div>
          </div>

          <div className='reports-programs mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/wul4w/gh7ss4yhho.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-0">Get a quick view of the member's journey, key milestones, and interaction history for easy tracking of their progress and engagement.</p>
              </div>
            </div>
          </div>

          <div id="content-wrapper" className="ContentDashboard">
            <div className=" mb-3">

              <div className='row'>
                <form className='row' method="post" id="searchMemberForm" onSubmit={handleSubmit}>
                  <div className='col-md-4 mt-2 pe-0'>
                    <div class="form-group">
                      <input type="text" className='form-control input-field-ui ps-5' placeholder="Search by Member ID" value={searchStr} onChange={(e) => setSearchStr(e.target.value.trim())} name="searchStr" id="searchStr" />
                      <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" class="input-icon-seacrh" alt="" />
                    </div>

                  </div>
                  <div className='col-md-3 mt-2'>
                    <button id="submitButton" className="btn btn-primary add-new-program-btn me-2" type="submit">Search</button>
                    {' '}
                    {
                      showUserProfile &&
                      <button className="btn btn-primary add-new-program-btn" type="button" onClick={() => {
                        handleExport();
                      }}><i className="bi bi-download"></i> Export</button>
                    }
                  </div>
                </form>
              </div>
              {
                showUserProfile === true &&
                <div className='row'>
                  <div className='col-md-12 mt-3'>
                    <div className={`profile-card-new priority-bg-${memberBasicInfo.priority}`}>
                      <div className='row'>
                        {/* <div className='col-md-auto'>
                      <div className="profile-image-1">
                        <img src="https://appcdn.goqii.com/user/user/l_1437644704.png" alt="Profile Image" />
                      </div>
                    </div> */}
                        <div className='col-md'>
                          <div className='row'>
                            <div className='col-md'>
                              <div className="profile-info">
                                <div className="info-line">
                                  <div className="info">
                                    <a className="name">{memberBasicInfo.memberName}</a>
                                    <span className="location"><i className="fa fa-map-marker" aria-hidden="true"></i>
                                      {memberBasicInfo.city}</span>
                                  </div>
                                </div>

                                <div className="info-line">
                                  <div className="info">
                                    <span className="gender big-text me-3">
                                      <i className="" aria-hidden="true">
                                        <img src={memberBasicInfo.gender === "M" ? "https://storage.googleapis.com/ksabupatest/2024/09/24/5shux/73lal8g48h.png" : "https://storage.googleapis.com/ksabupatest/2024/09/24/hhqe5/gl8hw4hhl9.png"} style={{ height: "18px" }} alt="" />
                                      </i> {memberBasicInfo.gender === "M" ? "Male" : "Female"} {memberBasicInfo.age}
                                    </span>
                                    <span className="physical big-text me-3">
                                      <i className=""><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/8u7jd/njt3shm0c4.png" style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.height} cms
                                    </span>
                                    <span className="physical big-text me-3">
                                      <i className=""><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/f4jhy/qhl8vj7lx4.png" style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.weight} kgs
                                    </span>
                                    <span className="physical big-text">
                                      <i className=""><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/zetj7/b4loqjyhqa.png" style={{ height: "18px" }} alt="" /></i> {memberBasicInfo.bmi}
                                    </span>
                                  </div>
                                </div>

                                <div className="info-line">
                                  <div className="info">
                                    <span className="email"><i className="fa fa-envelope pad-right-10" aria-hidden="true"></i>{memberBasicInfo.email}</span>
                                    <span className="phone"><i className="fa fa-phone pad-right-10" aria-hidden="true"></i>
                                      {memberBasicInfo.mobile}</span>
                                    <span className="phone"><i className="fa fa-phone pad-right-10" aria-hidden="true"></i>
                                      {memberBasicInfo.altMobile ? memberBasicInfo.altMobile : "-"}</span>

                                  </div>
                                </div>
                                <hr className='my-3' />
                                <div className="info-line">
                                  <div className="info row">
                                    <h5 className="membership-id col-3 mb-2"><b>Membership:</b> {memberBasicInfo.membershipNumber}</h5>
                                    <h5 className="membership-id col-3 mb-2"><b>Care Navigator:</b> {memberBasicInfo.carenavigatorName ? memberBasicInfo.carenavigatorName : "-"}</h5>
                                    <h5 className="membership-id col-6 mb-2"><b>Contract No:</b> {memberBasicInfo.contractNumber}
                                      {
                                        memberBasicInfo.priorityText && ` (${memberBasicInfo.priorityText})`
                                      }</h5>
                                  </div>
                                </div>
                                <div className="info-line">
                                  <div className="info row">
                                    <h5 className="membership-id col-3 mb-2"><b>Main Membership:</b> {memberBasicInfo.mainMembershipNumber}</h5>
                                    <h5 className="membership-id col-3 mb-2"><b>Membership Type:</b> {memberBasicInfo.policyMemberType ? memberBasicInfo.policyMemberType : "-"}</h5>
                                    <h5 className="dates col-6 mb-2"><b>Contract Name:</b> {memberBasicInfo.contractName}</h5>
                                  </div>
                                </div>
                                <div className="info-line">
                                  <div className="info row">
                                    <h5 className="dates col-3 mb-2"><b>Policy Start Date:</b> {memberBasicInfo.policyStartDate} </h5>
                                    <h5 className="dates col-3 mb-2"><b>Policy End Date:</b> {memberBasicInfo.policyEndDate}</h5>
                                  </div>
                                </div>
                                {/* <div className="info-line">
                              <div className="info row">
                                <h5 className="membership-id col-3 mb-2"><b>Contract No:</b> {memberBasicInfo.contractNumber}</h5>
                                <h5 className="dates col-3 mb-2"><b>Contract Name:</b> {memberBasicInfo.contractName}</h5>
                              </div>
                            </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  {
                    (showTelehealth == "Y" || showHealthLocker == "Y") &&
                    <div className='col-md-12 mt-3'>
                      <div className="button-row">
                        {
                          showTelehealth == "Y" && <button className="action-button blue-btn">Telehealth Consultation</button>
                        }
                        {
                          showHealthLocker == "Y" && <button className="action-button blue-btn " onClick={handleShowHealthLockerModal}>Health Locker</button>
                        }
                      </div>
                    </div>
                  }


                  {
                    memberPathways.length > 0 &&
                    <div className='col-md-12 mt-3'>
                      <div className='profile-card-new pb-1'>
                        <div className='row'>

                          {
                            memberPathways.map((item, index) => {
                              return <>

                                <div className='col-md-3 mb-2 px-2'>
                                  <div className='profile-box-n1'>

                                    <div className="info-line row">
                                      <div className='col'>
                                        <h3 className='profile-card-title mb-2'>{item.programName}</h3>
                                        <h5 className="program" ><b>Condition:</b><br /> {item.conditionName}</h5>
                                        <div className="info-line mt-2">
                                          <div className='row'>
                                            <div className='col'>
                                              <h5 className="status mb-0"><b>Pathway:</b></h5>
                                              <h5 className="status d-flex">{item.pathwayName}</h5>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div >

                              </>
                            })
                          }
                        </div>
                      </div>
                    </div>
                  }

                  {
                    familyMembers.length > 0 &&
                    <div className='col-md-12 mt-3'>
                      <div className='profile-card-new pb-1'>
                        <div className='row'>
                          {
                            familyMembers.map((item, index) => {
                              return <>
                                <a style={{ cursor: 'pointer' }} className='col-md-3 mb-2 px-2' onClick={() => handleFamilyMemberProfileClick(item.membershipNumber)}>
                                  <div className='profile-box-n1'>
                                    <div className="info-line row">
                                      <div className='col'>
                                        <h3 className='name mb-2'>{item.memberName}</h3>
                                        <h5 className="program mb-0" ><b>Member ID:</b> {item.membershipNumber}</h5>
                                      </div>
                                    </div>
                                  </div>
                                </a >
                              </>
                            })
                          }
                        </div>
                      </div>
                    </div>
                  }


                  {
                    distinctActions.length > 0 &&
                    <div className='row'>
                      <div className='col-md-12 mt-3'>
                        <div className="button-row">
                          <button className={`action-timelin-button ${filterText.length <= 0 ? "action-timeline-active" : ""}`} onClick={() => setFilterText([])}>All</button>
                          {
                            distinctActions.map((item, index) => {
                              return <button key={index} className={`action-timelin-button ${filterText.includes(item) ? "action-timeline-active" : ""}`} onClick={() => handleTagClick(item)}>{item}</button>
                            })
                          }
                        </div>
                      </div>
                    </div>
                  }

                  <div className='col-md-12 my-3'>
                    <div className='comntent-MemberDeEnrollment profile-card-timeline mb-3'>
                      <div className="row">
                        <div className="col-md-12">
                          <div id="container-timeline">
                            <ol className="timeline-profile">
                              {
                                events.map((item, index) => {
                                  return <li className="call" >
                                    <time>
                                      <small>{item.readableDate}</small>
                                      <div className="big-profile">{item.action}</div>
                                    </time>
                                    <article>
                                      <p>
                                        {item.description}
                                        {
                                          (item.details && (item.keyword == "hbsmedications" || item.keyword == "hbslab" || item.keyword == "hbshomecare" || item.keyword == "hbshomebasedvaccines" || item.keyword == "cncalls")) &&
                                          <i style={{ marginLeft: '10px', marginTop: "2px", fontSize: "13px", cursor: 'pointer', color: '#82B63A' }} className="fa fa-info-circle" aria-hidden="true" onClick={() => {
                                            handleDetailsModal(item.keyword, item.details)
                                          }}></i>
                                        }

                                      </p>
                                    </article>
                                  </li>
                                })
                              }

                            </ol>
                          </div>

                          {
                            eventsFound === false &&
                            <center><span>There are no records to display</span></center>
                          }

                          {showHealthLockerModal && <HealthLockerMP showHealthLockerModal={showHealthLockerModal} onHide={handleShowHealthLockerModal} handleCloseHealthLockerModal={handleCloseHealthLockerModal} memberBasicInfo={memberBasicInfo} />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </section>

      <Modal id="CallDetailsModal" show={showCallDetailModal} centered onHide={() => setShowCallDetailModal(false)} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
        <Modal.Header closeButton>
          <h1 className='modal-title' style={{ width: "100%" }}>Call Details</h1>
        </Modal.Header>
        <Modal.Body>
          <>
            {
              <div>
                <Row>
                  <Col className='mb-3'>
                    <label style={{ fontWeight: 'bold' }}>Date:</label><br />
                    <span>{callDetails.date}</span>
                  </Col>
                  <Col className='mb-3'>
                    <label style={{ fontWeight: 'bold' }}>Call Scheduled By:</label><br />
                    <span>{callDetails.scheduledByName}</span>
                  </Col>
                  <Col className='mb-3'>
                    <label style={{ fontWeight: 'bold' }}>Call By:</label><br />
                    <span>{callDetails.cnName}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className='mb-3'>
                    <label style={{ fontWeight: 'bold' }}>Call Type:</label><br />
                    <span>{callDetails.callType}</span>
                  </Col>
                  <Col className='mb-3'>
                    <label style={{ fontWeight: 'bold' }}>Call Sub-Type:</label><br />
                    <span>{callDetails.callSubType}</span>
                  </Col>
                  <Col className='mb-3'>
                    <label style={{ fontWeight: 'bold' }}>Call Status:</label><br />
                    <span>{callDetails.callCurrentStatus}</span>
                  </Col>
                </Row>
                <Row>
                  <Col className='mb-3'>
                    <label style={{ fontWeight: 'bold' }}>Streams:</label><br />
                    <span>{callDetails.streams}</span>
                  </Col>
                  <Col className='mb-3'>
                    <label style={{ fontWeight: 'bold' }}>Internal Notes:</label><br />
                    <span>{callDetails.internalNotes}</span>
                  </Col>
                  <Col className='mb-3'>
                    <label style={{ fontWeight: 'bold' }}>External Notes:</label><br />
                    <span>{callDetails.externalNotes}</span>
                  </Col>
                </Row>
              </div>
            }
          </>
        </Modal.Body>
      </Modal>

      <Modal id="hbsh" show={showHBSDetailModal} centered onHide={() => setShowHBSDetailModal(false)} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
        <Modal.Header closeButton>
          <h1 className='modal-title' style={{ width: "100%" }}>{hbsModalHeader}</h1>
        </Modal.Header>
        <Modal.Body>
          <>
            {

              showMr === true &&
              <div>
                <Row>
                  <Col>
                    <label>Membership</label><br />
                    <span>{hbsRequestDetail.membershipNo}</span>
                  </Col>
                  <Col>
                    <label>Name</label><br />
                    <span>{hbsRequestDetail.name}</span>
                  </Col>
                  <Col>
                    <label>Mobile</label><br />
                    <span>{hbsRequestDetail.mobileNumber}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Partner Clinic</label><br />
                    <span>{hbsRequestDetail.partnerName}</span>
                  </Col>
                  <Col>
                    <label>Pharmacy</label><br />
                    <span>{hbsRequestDetail.pharmacyName}</span>
                  </Col>
                  <Col>
                    <label>Delivery/Refill</label><br />
                    <span>{hbsRequestDetail.deliveryRefill}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>City</label><br />
                    <span>{hbsRequestDetail.city}</span>
                  </Col>
                  <Col>
                    <label>District</label><br />
                    <span>{hbsRequestDetail.district}</span>
                  </Col>
                  <Col>
                    <label>Deductible</label><br />
                    <span>{hbsRequestDetail.deductible}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Remarks</label><br />
                    <span>{hbsRequestDetail.remarks}</span>
                  </Col>
                  <Col>
                    <label>Medications</label><br />
                    <span><RenderStringWithBreaks inputString={hbsRequestDetail.medications} /></span>
                  </Col>
                  <Col>
                    <label>Status</label><br />
                    <span>{hbsRequestDetail.status}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Completion Date</label><br />
                    <span>{hbsRequestDetail.completionDate}</span>
                  </Col>
                </Row>
              </div>
            }

            {
              showLr === true &&
              <div>
                <Row>
                  <Col>
                    <label>Membership</label><br />
                    <span>{hbsRequestDetail.membershipNo}</span>
                  </Col>
                  <Col>
                    <label>Name</label><br />
                    <span>{hbsRequestDetail.name}</span>
                  </Col>
                  <Col>
                    <label>Mobile</label><br />
                    <span>{hbsRequestDetail.mobileNumber}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Partner Clinic</label><br />
                    <span>{hbsRequestDetail.partnerName}</span>
                  </Col>
                  <Col>
                    <label>Lab Provider</label><br />
                    <span>{hbsRequestDetail.labName}</span>
                  </Col>
                  <Col>
                    <label>Approval Number</label><br />
                    <span>{hbsRequestDetail.approvalNumber}</span>
                  </Col>

                </Row>
                <Row>
                  <Col>
                    <label>City</label><br />
                    <span>{hbsRequestDetail.city}</span>
                  </Col>
                  <Col>
                    <label>District</label><br />
                    <span>{hbsRequestDetail.district}</span>
                  </Col>
                  <Col>
                    <label>Nationality</label><br />
                    <span>{hbsRequestDetail.nationality}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Deductible</label><br />
                    <span>{hbsRequestDetail.deductible}</span>
                  </Col>
                  <Col>
                    <label>Remarks</label><br />
                    <span>{hbsRequestDetail.remarks}</span>
                  </Col>
                  <Col>
                    <label>Requested Lab Tests</label><br />
                    <span>{hbsRequestDetail.requestedLabTest}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Status</label><br />
                    <span>{hbsRequestDetail.status}</span>
                  </Col>
                  {hbsRequestDetail.serviceDate && <Col>
                    <label>Appointment Date</label><br />
                    <span>{hbsRequestDetail.serviceDate}</span>
                  </Col>}
                  <Col>
                    <label>Completion Date</label><br />
                    <span>{hbsRequestDetail.completionDate}</span>
                  </Col>
                  {fileUrl.length > 0 &&
                    <Col>
                      <label>Download Report</label><br />
                      <div className='DropDownAction'>
                        <div className="btn-group dropend">
                          <button type="button" className="btn btn-light dropdown-toggle action-dropdown-btn" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-download"></i>
                          </button>
                          <ul className="dropdown-menu">
                            {
                              fileUrl.map((item, index) => {
                                return <>
                                  <li className='list-class' key={index}><Link className="dropdown-item" target="_blank" to={item.link}>{item.name}</Link></li>
                                </>
                              })
                            }
                          </ul>
                        </div>
                      </div>
                    </Col>
                  }
                </Row>
              </div>
            }

            {
              showHcr === true &&
              <div>
                <Row>
                  <Col>
                    <label>Membership</label><br />
                    <span>{hbsRequestDetail.membershipNo}</span>
                  </Col>
                  <Col>
                    <label>Name</label><br />
                    <span>{hbsRequestDetail.name}</span>
                  </Col>
                  <Col>
                    <label>Mobile</label><br />
                    <span>{hbsRequestDetail.mobileNumber}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Approval Number</label><br />
                    <span>{hbsRequestDetail.approvalNumber}</span>
                  </Col>

                  <Col>
                    <label>Partner Clinic</label><br />
                    <span>{hbsRequestDetail.partnerClinic}</span>
                  </Col>
                  <Col>
                    <label>Home Health Care Provider</label><br />
                    <span>{hbsRequestDetail.providerName}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>City</label><br />
                    <span>{hbsRequestDetail.city}</span>
                  </Col>
                  <Col>
                    <label>District</label><br />
                    <span>{hbsRequestDetail.district}</span>
                  </Col>
                  <Col>
                    <label>Nationality</label><br />
                    <span>{hbsRequestDetail.nationality}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Deductible</label><br />
                    <span>{hbsRequestDetail.deductible}</span>
                  </Col>
                  <Col>
                    <label>Home Care Service</label><br />
                    <span>{hbsRequestDetail.requestedHomeHealth}</span>
                  </Col>
                  <Col>
                    <label>Remarks</label><br />
                    <span>{hbsRequestDetail.remarks}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Status</label><br />
                    <span>{hbsRequestDetail.status}</span>
                  </Col>
                  {hbsRequestDetail.serviceDate && <Col>
                    <label>Appointment Date</label><br />
                    <span>{hbsRequestDetail.serviceDate}</span>
                  </Col>}
                  <Col>
                    <label>Completion Date</label><br />
                    <span>{hbsRequestDetail.completionDate}</span>
                  </Col>
                  {fileUrl.length > 0 &&
                    <Col>
                      <label>Download Report</label><br />
                      <div className='DropDownAction'>
                        <div className="btn-group dropend">
                          <button type="button" className="btn btn-light dropdown-toggle action-dropdown-btn" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="bi bi-download"></i>
                          </button>
                          <ul className="dropdown-menu">
                            {
                              fileUrl.map((item, index) => {
                                return <>
                                  <li className='list-class' key={index}><Link className="dropdown-item" target="_blank" to={item.link}>{item.name}</Link></li>
                                </>
                              })
                            }
                          </ul>
                        </div>
                      </div>
                    </Col>
                  }
                </Row>
              </div>
            }

            {
              showHbv === true &&
              <div>
                <Row>
                  <Col>
                    <label>Child Membership</label><br />
                    <span>{hbsRequestDetail.membershipNo}</span>
                  </Col>
                  <Col>
                    <label>Child Name</label><br />
                    <span>{hbsRequestDetail.name}</span>
                  </Col>
                  <Col>
                    <label>Mobile</label><br />
                    <span>{hbsRequestDetail.mobileNumber}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>City</label><br />
                    <span>{hbsRequestDetail.city}</span>
                  </Col>
                  <Col>
                    <label>District</label><br />
                    <span>{hbsRequestDetail.district}</span>
                  </Col>
                  <Col>
                    <label>Nationality</label><br />
                    <span>{hbsRequestDetail.nationality}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Vaccine</label><br />
                    <span>{hbsRequestDetail.vaccine}</span>
                  </Col>

                  <Col>
                    <label>Partner Clinic</label><br />
                    <span>{hbsRequestDetail.partnerClinic}</span>
                  </Col>
                  <Col>
                    <label>Home Health Care Service</label><br />
                    <span>{hbsRequestDetail.requestedHomeHealth}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Deductible</label><br />
                    <span>{hbsRequestDetail.deductible}</span>
                  </Col>
                  <Col>
                    <label>Remarks</label><br />
                    <span>{hbsRequestDetail.remarks}</span>
                  </Col>
                  <Col>
                    <label>Status</label><br />
                    <span>{hbsRequestDetail.status}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <label>Completion Date</label><br />
                    <span>{hbsRequestDetail.completionDate}</span>
                  </Col>
                </Row>
              </div>
            }

            {serviceLogs.length > 0 && <div className='table-responsive mt-2' style={{ maxHeight: "380px", overflowX: "hidden" }}>
              <table className="table table-striped table-bordered mb-0">
                <thead style={{ position: "sticky", top: "0" }}>
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    serviceLogs?.map((item, index) => {
                      return <>
                        <tr style={{ verticalAlign: 'middle' }}>
                          <td className='bg-td-green'>{item.createdTime}</td>
                          <td className='bg-td-green'>{item.action}</td>
                        </tr>
                      </>
                    })
                  }

                </tbody>
              </table>
            </div>}
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MemberProfile;
